var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("time-left"),
        _c(
          "div",
          { staticClass: "confirm-header" },
          [
            _c(
              "iq-title",
              {
                class: {
                  desktopHeader: !_vm.isMobile,
                  mobileHeader: _vm.isMobile,
                },
              },
              [_vm._v(" Заполните данные по авто: ")]
            ),
          ],
          1
        ),
        _c(
          "el-col",
          { attrs: { span: 24 } },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "drivers-slots-form",
                staticStyle: { "margin-top": "16px" },
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-position": "top",
                },
              },
              [
                _c("iq-autocomplete-form", {
                  attrs: {
                    size: "fullWidth",
                    placeholder: "Номер авто полностью с регионом",
                    label: "Введите номер авто полностью с регионом:",
                    prop: "plate_truck",
                    options: _vm.querySearchAuto,
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.$refs.ruleForm.clearValidate("plate_truck")
                    },
                  },
                  model: {
                    value: _vm.ruleForm.plate_truck,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "plate_truck", $$v)
                    },
                    expression: "ruleForm.plate_truck",
                  },
                }),
                _vm.isStepTerminal
                  ? _c("iq-autocomplete-form", {
                      attrs: {
                        size: "fullWidth",
                        placeholder: "Номер прицепа полностью с регионом",
                        label: "Введите номер прицепа полностью с регионом:",
                        prop: "plate_trailer",
                        options: _vm.querySearchTrailer,
                      },
                      model: {
                        value: _vm.ruleForm.plate_trailer,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "plate_trailer", $$v)
                        },
                        expression: "ruleForm.plate_trailer",
                      },
                    })
                  : _vm._e(),
                _vm.isStepTerminal
                  ? _c("iq-select-form", {
                      attrs: {
                        "input-width": "fullWidth",
                        options: _vm.autoAxle,
                        label: "Общее количество осей авто с прицепом:",
                      },
                      model: {
                        value: _vm.ruleForm["truck_spindles_count"],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "truck_spindles_count",
                            _vm._n($$v)
                          )
                        },
                        expression: "ruleForm['truck_spindles_count']",
                      },
                    })
                  : _vm._e(),
                _c("iq-autocomplete-form", {
                  attrs: {
                    size: "fullWidth",
                    options: _vm.querySearchName,
                    placeholder: "Введите имя и фамилию",
                    label: "Имя и фамилия водителя",
                    prop: "name",
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.$refs.ruleForm.clearValidate("name")
                    },
                  },
                  model: {
                    value: _vm.ruleForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "name", $$v)
                    },
                    expression: "ruleForm.name",
                  },
                }),
                _c("iq-autocomplete-form", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "+7 ### ###-##-##",
                      expression: "'+7 ### ###-##-##'",
                    },
                  ],
                  attrs: {
                    size: "fullWidth",
                    options: _vm.querySearchPhone,
                    placeholder: "Введите телефон водителя",
                    label: "Телефон водителя",
                    prop: "phone",
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.$refs.ruleForm.clearValidate("phone")
                    },
                  },
                  model: {
                    value: _vm.ruleForm.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "phone", $$v)
                    },
                    expression: "ruleForm.phone",
                  },
                }),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "iq-button",
                      {
                        attrs: { size: "fullWidth" },
                        on: {
                          onClick: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v(" Подтвердить выбор ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "iq-button",
          {
            attrs: { color: "second", size: "fullWidth" },
            on: {
              onClick: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v(" Назад ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }